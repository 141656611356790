import React, {Fragment} from 'react';
import {withRouter, Link} from 'react-router-dom';



const Header = (props) => {
   return ( 
		<Fragment>
            <div className="header">
            {/* TOPBAR */}
            <div className="topbar">
                <div className="container">
                <div className="row">
             
                    <div className="col-sm-5 col-md-6">
                    <div className="topbar-left">
                        <div className="welcome-text">
                     
                        </div>
                    </div>
                    </div>
        
                    <div className="col-sm-7 col-md-6">
                    <div className="topbar-right">
                        {/* 
                        <ul className="topbar-menu">

                        <li><a href="career.html" title="Career">Career</a></li>
                        <li><a href="contact-feedback.html" title="Give Feedback">Give Feedback</a></li>
                        <li><a href="Contact.html" title="Contact Us">Contact Us</a></li>

                        </ul>
                       
                        <ul className="topbar-sosmed">
                        <li>
                            <a href="#"><i className="fa fa-facebook" /></a>
                        </li>
                        <li>
                            <a href="#"><i className="fa fa-twitter" /></a>
                        </li>
                        <li>
                            <a href="#"><i className="fa fa-instagram" /></a>
                        </li>
                        </ul>
                         */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* TOPBAR LOGO SECTION */}
            <div className="topbar-logo">
                <div className="container">
                <div className="contact-info">
                    <div className="box-icon-1">

                        <div className="body-content">
                            <div className="icon">
                                <div className="fa fa-envelope-o"/>
                                <span>&nbsp;&nbsp;&nbsp;info@qsalatam.com</span>
                                
                            </div>
                        </div>
                    </div>
                    <div className="box-icon-1">
                        <div className="body-content">
                            <div className="icon">
                                <div className="fa fa-phone"/>
                                <span>&nbsp;&nbsp;+507 388-4388</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
//export default Header;
export default withRouter(Header);