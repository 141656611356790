import React, {Fragment} from 'react';



const Diseno = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Diseño y arquitectura de la seguridad de la información</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>An&aacute;lisis detallado de las necesidades del negocio en Ciber Seguridad</li>
                            <li>Definici&oacute;n de Prioridades en base a los riesgos tecnol&oacute;gicos del negocio</li>
                            <li>Definici&oacute;n de las capas de arquitectura necesarias para proteger la operaci&oacute;n</li>
                            <li>Establecimiento de hoja de ruta</li>
                            <li>Apoyo en estructuraci&oacute;n de RFP para adquisici&oacute;n de Tecnolog&iacute;as</li>
                            <li>Apoyo en evaluaci&oacute;n de opciones presentadas por los proveedores de infraestructura de seguridad de la informaci&oacute;n</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Diseno;