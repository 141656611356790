import React, {Fragment} from 'react';



const Programa = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Programa integral de seguridad de la información</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                          <blockquote>
                          <p>Este servicio nace de la necesidad de nuestros clientes de implementar un framework o metodolog&iacute;a de seguridad de la informaci&oacute;n que le permita identificar, corregir y mejorar de manera continua todo el riesgo tecnol&oacute;gico de su organizaci&oacute;n que pueda afectar los tres pilares fundamentales de seguridad que son la Disponibilidad, la Confidencialidad, y la Integridad de la informaci&oacute;n de los sistemas que la portan.</p>
                          </blockquote>   
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Programa;