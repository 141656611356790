import React, {Fragment} from 'react';



const Vulnerabilidades = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Gestión de las vulnerabilidades</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                        <p><strong>La Gesti&oacute;n de Vulnerabilidades Interna &amp; Externa:</strong></p>
                        <p>Permite tener una visi&oacute;n clara de todos los sistemas que pueden estar afectados por una o m&uacute;ltiples vulnerabilidades las cuales deber&iacute;an ser solucionadas, ya que podr&iacute;an ser un posible vector para un atacante malintencionado que quiera comprometer a los activos tecnol&oacute;gicos de una organizaci&oacute;n.</p>
                        <p>como tal es un proceso que consiste en identificar las vulnerabilidades, evaluar su criticidad y corregirlas para que los sistemas de la informaci&oacute;n sean m&aacute;s seguros.</p>
                        </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Vulnerabilidades;