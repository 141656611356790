import React, {Fragment} from 'react';



const Seguridad = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
				<div class="col-sm-12 col-md-12">
					<div class="title-page">Nosotros</div>
				</div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">QUIENES SOMOS</div>
                            <p style={{textAlign: 'justify'}}>Quantum Security Advisors es una firma enfocada en ciberseguridad y soluciones especializadas, dirigida a proyectos de transformación de seguridad, mediante la conformación de análisis de vulnerabilidad, planificación, diseño e implantación de proyectos integrados a la medida.</p>
                            <p style={{textAlign: 'justify'}}>Contamos con un equipo de profesionales con más de 20 años de experiencia en este campo, que por medio de nuestra estrategia de trabajo, buscamos siempre ofrecer las mejores soluciones para asesorar y orientar a nuestros clientes, sobre las mejores prácticas de gestión de seguridad de la información.</p>
                        </blockquote>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">Misión</div>
                            <p style={{textAlign: 'justify'}}>Reducir la vulnerabilidad del medio digital, implantando sistemas de seguridad vanguardista, enfocados en las necesidades reales de nuestros clientes, alineado con las mejores prácticas para proteger los activos de las organizaciones con la más avanzada tecnología disponible en el mercado.</p>
                        </blockquote>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">Visión</div>
                            <p style={{textAlign: 'justify'}}>Alcanzar la excelencia empresarial, basada en la especialización de soluciones de ciberseguridad y ciberdefensa de forma confiable e innovadora, contribuyendo a fortalecer la seguridad colectiva.</p>
                        </blockquote>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">Valores</div>
                            <p style={{textAlign: 'justify'}}>Somos una organización <strong>comprometida</strong>, <strong>innovando</strong> para combatir las vulnerabilidades del mercado de manera <strong>creativa</strong>, combinando tecnología avanzada con <strong>integridad</strong> y ética para generar la <strong>confianza</strong> de nuestros clientes.</p>
                        </blockquote>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">POR QUE NOSOTROS</div>
                            <p style={{textAlign: 'justify'}}>Los servicios ofrecidos por Quantum Security Advisors, están sujetos a altos estándares de la industria a la vez que nos comprometemos a cumplir a cabalidad con los siguientes puntos esenciales en todo proyecto de cyber seguridad.&nbsp;</p>
                            <ul>
                                <li style={{textAlign: 'justify'}}>Confidencialidad – Aseguramos el uso adecuado de la información de nuestros clientes, a través de la firma de un acuerdo de confidencialidad.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Conocimiento – Más de 20 años de experiencia en el ámbito de la seguridad de la información. Certificaciones internacionales tales como CISSP, CEH, ECSA, GPEN, GCIH, OSCP, OSWP, OSCE, Linux+, Network+, entre otras.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Ética – Cumplimos con los códigos internacionales de EC-COUNCIL, ISACA, Sans Institute e ISC2.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Experiencia – Participación en trabajos similares a los presentados en esta propuesta en empresas de los sectores Financiero, Gobierno, Legal, Manufactura y Servicios dentro y fuera del país.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Independencia – No dependemos de ningún proveedor de herramientas de seguridad en forma exclusiva, por lo que nuestras recomendaciones están basadas exclusivamente en las necesidades de nuestros clientes.&nbsp;</li>
                            </ul>
                            <ul>
                                <li style={{textAlign: 'justify'}}>Enfoque – Preventivo / Detectivo / Correctivo.</li>
                            </ul>
                        </blockquote>
                        </div>
                    </div>
                
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Seguridad;