import React, {Fragment} from 'react';
// Routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './componentes/layout/Header';
import Navegacion from './componentes/layout/Navegacion';
import Inicio from './componentes/inicio/Inicio';
import Nosotros from './componentes/nosotros/Nosotros';

import Misionvision from './componentes/nosotros/Misionvision';
import Porquenosotros from './componentes/nosotros/Porquenosotros';
import Certificaciones from './componentes/certificaciones/Certificaciones';

import Noticias from './componentes/noticias/Noticias';


import Seguridad from './componentes/services/Seguridad';
import Waf from './componentes/services/Waf';
import Pentesting from './componentes/services/Pentesting';
import Vulnerabilidades from './componentes/services/Vulnerabilidades';
import Codigofuente from './componentes/services/Codigofuente';
import Evaluacion from './componentes/services/Evaluacion';
import Diseno from './componentes/services/Diseno';
import Auditorias from './componentes/services/Auditorias';
import Cyber from './componentes/services/Cyber';

import Programa from './componentes/services/Programa';
import Forense from './componentes/services/Forense';
import Barrido from './componentes/services/Barrido';
import Hardening from './componentes/services/Hardening';
import Cursos from './componentes/services/Cursos';
import Contacto from './componentes/contacto/Contacto';



function App() {
  return (
    <Router>
    <Fragment>
        <Header />
        <Navegacion />
            <Switch>
                <Route exact path="/" component={Inicio} />
                <Route exact path="/nosotros" component={Nosotros} />
                <Route exact path="/misionvision" component={Misionvision} />
                <Route exact path="/porquenosotros" component={Porquenosotros} />
                <Route exact path="/certificaciones" component={Certificaciones} />
                <Route exact path="/noticias" component={Noticias} />

                <Route exact path="/seguridad" component={Seguridad} />
                <Route exact path="/waf" component={Waf} />
                <Route exact path="/pentesting" component={Pentesting} />
                <Route exact path="/vulnerabilidades" component={Vulnerabilidades} />
                <Route exact path="/codigofuente" component={Codigofuente} />
                <Route exact path="/evaluacion" component={Evaluacion} />
                <Route exact path="/diseno" component={Diseno} />
                <Route exact path="/auditorias" component={Auditorias} />
                <Route exact path="/cyber" component={Cyber} />
                <Route exact path="/programa" component={Programa} />
                <Route exact path="/forense" component={Forense} />
                <Route exact path="/barrido" component={Barrido} />
                <Route exact path="/hardening" component={Hardening} />
                <Route exact path="/cursos" component={Cursos} />
                <Route exact path="/contacto" component={Contacto} />

                
            </Switch>
    </Fragment>
    </Router>
  );
}

export default App;
