import React, {Fragment} from 'react';



const Cyber = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Cyber Intelligence</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>A trav&eacute;s de este servicio podemos monitorear todo el internet (Deep Web, Dark Web, Black Markets, Redes Sociales, Buscadores, Newsgroup, etc) buscando patrones y comportamientos maliciosos sobre nuestros clientes y sus activos digitales.</li>
                            <li>Podemos predecir ataques de DDoS/DoS, Ingenier&iacute;a Social, Spear Phishing, APT&acute;s, 0 Days, entre otros.</li>
                            <li>Identificaci&oacute;n de documentos robados, passwords expuestos, aplicaciones vulnerables, malas pr&aacute;cticas de seguridad.</li>
                            <li>Identificaci&oacute;n y apoyo en la mitigaci&oacute;n de campa&ntilde;as de desprestigio sobre una marca o empresa.</li>
                            <li>Identificaci&oacute;n de equipos comprometidos, c&oacute;digo fuente o patentes robadas.</li>
                            <li>Apoyo experto en como protegerse de las amenazas identificadas o futuros ataques.</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Cyber;