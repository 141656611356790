import React, {Fragment} from 'react';
import Banner01 from '../banner/Banner01';



const Nosotros = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Seguridad para entornos virtuales</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <p><strong>Protecci&oacute;n para su Infraestructura en la Nube</strong></p>
                            <p>Hillstone proporciona los dos mejores productos de su clase (CloudHive y CloudEdge) para defender su infraestructura en la nube, aplicaciones y datos, compatibles con cualquier entorno virtualizado. Estas soluciones incluyen:</p>
                            <ul>
                            <li>Micro-segmentaci&oacute;n para Centros de Datos Virtualizados</li>
                            <li>La mejor soluci&oacute;n de seguridad para virtualizaciones de funciones de red (NFV)</li>
                            <li>Protecci&oacute;n para las Nubes Privadas Virtuales (VPC) en la nube p&uacute;blica</li>
                            </ul>
                            <p><strong>Gesti&oacute;n de Seguridad Centralizada</strong></p>
                            <p>Con an&aacute;lisis y administraci&oacute;n basados en la nube, una plataforma de administraci&oacute;n de seguridad y una plataforma de auditor&iacute;a de seguridad, el conjunto de productos de administraci&oacute;n de seguridad de Hillstone proporciona a los equipos de seguridad las herramientas que necesitan para administrar sus redes. Estos incluyen:</p>
                            <ul>
                            <li>Plataforma anal&iacute;tica basada en la nube para la gesti&oacute;n de seguridad</li>
                            <li>Soluci&oacute;n centralizada de administraci&oacute;n de seguridad, configuraci&oacute;n y monitoreo</li>
                            <li>Retenci&oacute;n de registros centralizados y recuperaci&oacute;n de alta velocidad</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Nosotros;