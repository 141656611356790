import React, {Fragment} from 'react';



const Forense = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Investigación Forense</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>Servicios de consultor&iacute;a para la recolecci&oacute;n de evidencia digital, luego de que se ha dado un evento de seguridad.</li>
                            <li>La inform&aacute;tica forense se encarga de analizar sistemas inform&aacute;ticos en busca de evidencia que colabore a llevar adelante una causa judicial o una negociaci&oacute;n extrajudicial.</li>
                            <li>Dicho de otra forma, son las t&eacute;cnicas de capturar, procesar e investigar la informaci&oacute;n procedente de sistemas inform&aacute;ticos utilizando una metodolog&iacute;a con el fin de que pueda ser utilizada ante las autoridades competentes.</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Forense;