import React, {Fragment} from 'react';



const Evaluacion = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Evaluación de Riesgo del Sistema SCADA</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>Los sistemas de control distribuido (DCS) y los de Supervisi&oacute;n, Control y Adquisici&oacute;n de Datos (SCADA) son cr&iacute;ticos para la continuidad de las operaciones de las industrias de misi&oacute;n cr&iacute;tica tal es el caso de: Empresas del Sector de Energ&iacute;a El&eacute;ctrica, Trasporte Metro, Canal de Panam&aacute;, Distribuci&oacute;n de Agua Potable, Hospitales, Plantas de Procesamiento, entre otros.</li>
                            <li>La evaluaci&oacute;n de estos sistemas, son importantes tanto para la seguridad de las personas que operan en la Planta y la continuidad de las operaciones, por lo cual requieren evaluaciones exhaustivas e integrales de riesgos, incluyendo a la infraestructura tecnol&oacute;gica que los soporta.&nbsp;&nbsp;</li>
                              <li>Nuestro equipo de profesionales se encuentra certificado internacionalmente como GIAC GICSP (<em>Global Industrial Cyber Security Professional</em>) por el SANS Institute de EE. UU. Asimismo, nuestros profesionales cuentan con experiencia evaluando riesgos de ciberseguridad en redes de control.</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Evaluacion;