import React, {Fragment,useEffect } from 'react';




const Banner = () => {

 

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "js/script.js";
        script.async = true;
        document.body.appendChild(script); 
    });


   return ( 
		<Fragment>
            {/* BANNER */}
            <div id="slides" className="section banner">
                <ul className="slides-container">
                <li>
                    <img src="images/imagen22.jpg" alt />
                    <div className="overlay-bg" />
                    <div className="container">
                    <div className="wrap-caption">
                        <h2 className="caption-heading">
                        CIBERSEGURIDAD INTEGRAL
                        </h2>
                        <p className="excerpt">Contamos con productos de seguridad integral, compuesto por desarrollos a la medida, soluciones que ayudan a las empresas a crear una estrategia integral de ciberseguridad.</p>	
                    </div>
                    </div>
                </li>    
   
                <li>
                    <img src="images/imagen02-1920X960.jpg" alt />
                    <div className="overlay-bg" />
                    <div className="container">
                    <div className="wrap-caption">
                        <h2 className="caption-heading">
                         IMPORTANCIA DE LA CIBERSEGURIDAD
                        </h2>
                        <p className="excerpt">Toda empresa, institución o gobierno debe invertir en este terreno, pues los daños que pueden ocasionar estos ataques son numerosos. Incluyendo el impacto por perdida de vidas humanas.</p>	
                    </div>
                    </div>
                </li>
                <li>
                    <img src="images/imagen06-1920X960.jpg" alt />
                    <div className="overlay-bg" />
                    <div className="container">
                    <div className="wrap-caption">
                        <h2 className="caption-heading">
                            GESTIÓN DE VULNERABILIDAD
                        </h2>
                        <p className="excerpt">Permite tener una visión clara de todos los sistemas que pueden estar afectados por una o múltiples vulnerabilidades</p>	
                    </div>
                    </div>
                </li>
                <li>
                    <img src="images/imagen1920x960.jpg" alt />
                    <div className="overlay-bg" />
                    <div className="container">
                    <div className="wrap-caption">
                        <h2 className="caption-heading">
                        INVESTIGACIÓN FORENSE
                        </h2>
                        <p className="excerpt">Servicios de consultoría para la recolección de evidencia digital, luego de que se ha dado un evento de seguridad.</p>	
                    </div>
                    </div>
                </li> 
                </ul>
                <nav className="slides-navigation">
                <div className="container">
                    <a href="#" className="next">
                    <i className="fa fa-chevron-right" />
                    </a>
                    <a href="#" className="prev">
                    <i className="fa fa-chevron-left" />
                    </a>
                </div>
                </nav>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Banner;