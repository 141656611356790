import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const style = {
    width: "197px",
    height: "71px"
  };

const Navegacion = () => {


    return ( 
        <Fragment>
             {/* NAVBAR SECTION */}
             <div className="navbar navbar-main">
                <div className="container container-nav">
                <div className="rowe">
                    <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                    </div>
                    <Link className="navbar-brand" to={"/"}>
                       <img src="images/logos/logo.jpg" />
                    </Link>
                    <nav className="navbar-collapse collapse">
                    <ul className="nav navbar-nav navbar-left">
                        <li className="dropdown">
                           <Link to={"/"} >INICIO</Link>
                        </li>

                        <li className="dropdown">
                           <Link to={"/nosotros"} >NOSOTROS</Link>
                        </li>
                        {/* 
                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false">NOSOTROS <span className="caret" /></a>
                            <ul className="dropdown-menu">
                                <li><Link to={"/nosotros"}>QUIENES SOMOS</Link></li>
                                <li><Link to={"/misionvision"}>MISIÓN - VISIÓN - VALORES</Link></li>
                                <li><Link to={"/porquenosotros"}>POR QUE NOSTROS</Link></li>
                            </ul>
                        </li>
                        */}

                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false">SERVICIOS <span className="caret" /></a>
                            <ul className="dropdown-menu">
                                <li><Link to={"/seguridad"}>SEGURIDAD PARA ENTORNOS VIRTUALES</Link></li>
                                <li><Link to={"/waf"}>WAF & DDOS PROTECTION</Link></li>
                                <li><Link to={"/pentesting"}>PENTESTING (Pruebas de Penetración)</Link></li>
                                <li><Link to={"/vulnerabilidades"}>GESTIÓN DE VULNERABILIDAD</Link></li>
                                <li><Link to={"/codigofuente"}>REVISIÓN DE CÓDIGO FUENTE</Link></li>
                                <li><Link to={"/evaluacion"}>EVALUACIÓN DE RIESGO DEL SISTEMAS SCADA</Link></li>
                                <li><Link to={"/diseno"}>DISEÑO DE ARQUITECTURA DE SEGURIDAD DE LA INFORMACIÓN</Link></li>
                                <li><Link to={"/auditorias"}>AUDITORIAS DE IMPLEMENTACIÓN ISO 27001 / 27002</Link></li>
                                <li><Link to={"/cyber"}>CYBER INTELLIGENCE</Link></li>
                                <li><Link to={"/programa"}>PROGRAMA INTEGRAL DE SEGURIDAD DE LA INFORMACIÓN</Link></li>
                                <li><Link to={"/forense"}>INVESTIGACIÓN FORENSE</Link></li>
                                <li><Link to={"/barrido"}>BARRIDO DE SEÑALES Y FRECUENCIAS RF</Link></li>
                                <li><Link to={"/hardening"}>HARDENING DE SISTEMAS Y APLICACIONES</Link></li>
                                <li><Link to={"/cursos"}>CURSOS DE ETHICAL HACKING</Link></li>
                            </ul>
                        </li>

                        <li className="dropdown">
                           <Link to={"/certificaciones"} >CERTIFICACIONES</Link>
                        </li>

                        <li className="dropdown">
                           <Link to={"/noticias"} >NOTICIAS</Link>
                        </li>

                        {/* 
                        <li className="dropdown">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false">PAGES <span className="caret" /></a>
                        <ul className="dropdown-menu">
                            <li><a href="faq.html">FAQ</a></li>
                            <li><a href="pricing-table.html">PRICING TABLE</a></li>
                            <li><a href="404page.html">404 PAGE</a></li>
                            <li><a href="career.html">CAREER</a></li>
                        </ul>
                        </li>
                        <li className="dropdown">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false">PROJECTS <span className="caret" /></a>
                        <ul className="dropdown-menu">
                            <li><a href="project-grid.html">GRID LAYOUT</a></li>
                            <li><a href="project-grid-2.html">GRID LAYOUT WITH LIGHTBOX</a></li>
                            <li><a href="project-detail.html">SINGLE PROJECT</a></li>
                        </ul>
                        </li>
                        <li className="dropdown">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false">NEWS <span className="caret" /></a>
                        <ul className="dropdown-menu">
                            <li><a href="news-grid.html">GRID BAR</a></li>
                            <li><a href="news-sidebar.html">SIDEBAR</a></li>
                            <li><a href="news-detail.html">SINGLE NEWS</a></li>
                        </ul>
                        </li>
                        */}
                        <li className="dropdown">
                        <Link to={"/contacto"} >CONTÁCTENOS</Link>
                        {/* 
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" role="button" aria-haspopup="true" aria-expanded="false">CONTÁCTENOS <span className="caret" /></a>
                        <ul className="dropdown-menu">
                            <li><a href="contact.html">CONTACT US</a></li>
                            <li><a href="contact-quote.html">GET A QUOTE</a></li>
                        </ul>
                        */}
                        </li>
                    </ul>

                    </nav>
                </div>
                </div>
            </div>
           
        </Fragment>
     );
}
 
export default Navegacion;