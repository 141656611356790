import React, {Fragment} from 'react';



const Misionvision = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <ol><li></li><li></li></ol>
                    <div className="title-page">MISIÓN - VISIÓN - VALORES</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">Misión</div>
                            <p style={{textAlign: 'justify'}}>Reducir la vulnerabilidad del medio digital, implantando sistemas de seguridad vanguardista, enfocados en las necesidades reales de nuestros clientes, alineado con las mejores prácticas para proteger los activos de las organizaciones con la más avanzada tecnología disponible en el mercado.</p>
                        </blockquote>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">Visión</div>
                            <p style={{textAlign: 'justify'}}>Alcanzar la excelencia empresarial, basada en la especialización de soluciones de ciberseguridad y ciberdefensa de forma confiable e innovadora, contribuyendo a fortalecer la seguridad colectiva.</p>
                        </blockquote>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <div className="director-position">Valores</div>
                            <p style={{textAlign: 'justify'}}>Somos una organización <strong>comprometida</strong>, <strong>innovando</strong> para combatir las vulnerabilidades del mercado de manera <strong>creativa</strong>, combinando tecnología avanzada con <strong>integridad</strong> y ética para generar la <strong>confianza</strong> de nuestros clientes.</p>
                        </blockquote>
                        </div>
                    </div>
               
                </div>
            </div>
		</Fragment>
    )

}
//export default withRouter(Header);
export default Misionvision;