import React, {Fragment} from 'react';



const Pentesting = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Pentesting (Pruebas de Penetración)</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>Se realizan para comprobar la seguridad de su infraestructura, aplicaciones y sistemas.</li>
                            <li>Se utilizan las mismas t&eacute;cnicas y herramientas que los hackers.</li>
                            <li>Trabajamos con metodolog&iacute;as internacionales tales como OWASP, ISSAF, OSSTMM, PTES Framework.</li>
                            <li>Detallados informes t&eacute;cnicos y ejecutivos le permiten conocer el estado actual de su red, recomendaciones de seguridad y remediaciones.</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Pentesting;