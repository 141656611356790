import React, {Fragment} from 'react';



const Porquenosotros = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <ol><li></li><li></li></ol>
                    <div className="title-page">POR QUE NOSOTROS</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>
                            <p style={{textAlign: 'justify'}}>Los servicios ofrecidos por Quantum Security Advisors, están sujetos a altos estándares de la industria a la vez que nos comprometemos a cumplir a cabalidad con los siguientes puntos esenciales en todo proyecto de cyber seguridad.&nbsp;</p>
                            <ul>
                                <li style={{textAlign: 'justify'}}>Confidencialidad – Aseguramos el uso adecuado de la información de nuestros clientes, a través de la firma de un acuerdo de confidencialidad.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Conocimiento – Más de 20 años de experiencia en el ámbito de la seguridad de la información. Certificaciones internacionales tales como CISSP, CEH, ECSA, GPEN, GCIH, OSCP, OSWP, OSCE, Linux+, Network+, entre otras.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Ética – Cumplimos con los códigos internacionales de EC-COUNCIL, ISACA, Sans Institute e ISC2.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Experiencia – Participación en trabajos similares a los presentados en esta propuesta en empresas de los sectores Financiero, Gobierno, Legal, Manufactura y Servicios dentro y fuera del país.&nbsp;</li>
                            </ul>
                            <ul style={{textAlign: 'justify'}}>
                                <li>Independencia – No dependemos de ningún proveedor de herramientas de seguridad en forma exclusiva, por lo que nuestras recomendaciones están basadas exclusivamente en las necesidades de nuestros clientes.&nbsp;</li>
                            </ul>
                            <ul>
                                <li style={{textAlign: 'justify'}}>Enfoque – Preventivo / Detectivo / Correctivo.</li>
                            </ul>
                        </blockquote>
                        </div>
                    </div>
                
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Porquenosotros;