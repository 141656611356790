import React, {Fragment} from 'react';



const Cursos = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                      <div className="title-page">Cursos de Ethical Hacking</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>El curso de&nbsp;<strong>Penetration Testing&nbsp;</strong>est&aacute; orientado a toda persona que est&eacute; interesada en comprender las t&eacute;cnicas y herramientas de ataque que utilizan los hackers actualmente, as&iacute; como tambi&eacute;n sacar provecho de las mismas para aumentar la seguridad de las organizaciones.<br /> Durante las clases realizaremos ataques reales contra equipos como ser servidores de email, servidores web y otros. Atacaremos protocolos conocidos como TCP/IP, explotaremos sotfware identificando bugs de programaci&oacute;n y evadiremos sistemas de defensa como honeypots, firewalls y sistemas de detecci&oacute;n de intrusos.</li>

                            <li><strong>LABORATORIO:</strong><br /> Durante el curso se realizar&aacute;n pruebas de ataque sobre sistemas reales y se verificar&aacute; el funcionamiento de las herramientas de seguridad m&aacute;s utilizadas en el mercado.&nbsp;</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Cursos;