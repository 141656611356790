import React, {Fragment} from 'react';



const Auditorias = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Auditorias de Implementación ISO 27001 / 27002</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>Pol&iacute;ticas y Procedimientos de seguridad</li>
                            <li>Organizaci&oacute;n del &aacute;rea de seguridad de la informaci&oacute;n</li>
                            <li>Manejo de activos</li>
                            <li>Seguridad en recursos humanos</li>
                            <li>Seguridad f&iacute;sica y ambiental</li>
                            <li>Administraci&oacute;n de las comunicaciones y operaciones</li>
                            <li>Control de Acceso</li>
                            <li>Adquisiciones de sistemas de informaci&oacute;n, desarrollo y mantenimiento</li>
                            <li>Administraci&oacute;n de los incidentes relacionados con la seguridad de la informaci&oacute;n</li>
                            <li>Administraci&oacute;n de la continuidad del negocio</li>
                            <li>Cumplimiento</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Auditorias;