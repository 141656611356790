import React, {Fragment} from 'react';



const Noticias = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Noticias</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">

                       <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <a href="https://cuadernosdeseguridad.com/2020/08/dispositivos-control-accesos-ciberataque-trend-micro/" target="_blank" title="Industrial Complex">    
                                <div className="media gbr">
                                    <img src="images/noticias/reconocimiento-facial.jpg" alt className="img-responsive" style={{width: 500, height: 200}}/>
                                </div>
                                </a>
                                <div className="body">
                                    <div className="title"><a href="https://cuadernosdeseguridad.com/2020/08/dispositivos-control-accesos-ciberataque-trend-micro/" target="_blank" title>Ciberseguridad, clave a la hora de elegir un dispositivo de control de acceso de reconocimiento facial</a></div>
                                    <div className="meta">
                                        <span className="date"><i className="fa fa-clock-o" /> Ago 20, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            {/* BOX 2 */}
                            <div className="box-news-1">
                            <a href="https://www.laestrella.com.pa/amp/cafe-estrella/tecnologia/200819/llamado-fortalecer-ciberseguridad-panama-region" target="_blank" title="Industrial Complex">    
                            <div className="media gbr">
                                <img src="images/noticias/5f3c584f24394.jpeg" alt className="img-responsive" style={{width: 500, height: 200}}/>
                            </div>
                            </a>
                            <div className="body">
                                <div className="title"><a href="https://www.laestrella.com.pa/amp/cafe-estrella/tecnologia/200819/llamado-fortalecer-ciberseguridad-panama-region" target="_blank" title>Un llamado a fortalecer la ciberseguridad en Panamá y la región</a></div>
                                <div className="meta">
                                <span className="date"><i className="fa fa-clock-o" /> Ago 19, 2020</span>
                                </div>
                            </div>
                            </div>
                        </div>


                        <div className="col-sm-6 col-md-4">
                            {/* BOX 1 */}
                            <div className="box-news-1">
                            <a href="https://www.laestrella.com.pa/amp/nacional/200818/pagina-web-ministerio-educacion-recibe-ataque-cibernetico" target="_blank" title="Industrial Complex">
                                <div className="media gbr">
                                <img src="images/noticias/5f35d31d7f4c5.jpeg" alt className="img-responsive" style={{width: 500, height: 200}}/>
                                </div>
                            </a>
                            <div className="body">
                                <div className="title"><a href="https://www.laestrella.com.pa/amp/nacional/200818/pagina-web-ministerio-educacion-recibe-ataque-cibernetico" target="_blank" title>Página web del Ministerio de Educación recibe ataque cibernético</a></div>
                                <div className="meta">
                                <span className="date"><i className="fa fa-clock-o" /> Ago 18, 2020</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>  
                    <div className="row">  
                        <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <a href="https://www.dinero.com/hablan-las-marcas/articulo/la-ciberseguridad--cada-vez-mas-relevante-para-las-organizaciones/293516" target="_blank" title="Industrial Complex">    
                                <div className="media gbr">
                                    <img src="images/noticias/293512_1.jpg" alt className="img-responsive" style={{width: 500, height: 200}}/>
                                </div>
                                </a>
                                <div className="body">
                                    <div className="title"><a href="https://www.dinero.com/hablan-las-marcas/articulo/la-ciberseguridad--cada-vez-mas-relevante-para-las-organizaciones/293516" target="_blank" title>La ciberseguridad, cada vez más relevante para las organizaciones</a></div>
                                    <div className="meta">
                                        <span className="date"><i className="fa fa-clock-o" /> Jul 24, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <a href="https://www.laestrella.com.pa/cafe-estrella/tecnologia/200724/70-empresas-latinoamerica-sufren-ataques-ciberneticos-pandemia" target="_blank" title="Industrial Complex">    
                                <div className="media gbr">
                                    <img src="images/noticias/5e121c3545879.jpeg" alt classname="img-responsive" style={{width: 500, height: 200}} />

                                </div>
                                </a>
                                <div className="body">
                                    <div className="title"><a href="https://www.laestrella.com.pa/cafe-estrella/tecnologia/200724/70-empresas-latinoamerica-sufren-ataques-ciberneticos-pandemia" target="_blank" title>El 70% de las empresas de Latinoamérica sufren ataques cibernéticos durante la pandemia</a></div>
                                    <div className="meta">
                                        <span className="date"><i className="fa fa-clock-o" /> Jul 24, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <a href="https://www.europapress.es/portaltic/ciberseguridad/noticia-firefox-suspende-funcion-compartir-archivos-debido-uso-ciberataques-20200708114748.html" target="_blank" title="Industrial Complex">    
                                <div className="media gbr">
                                    <img src="images/noticias/master.880ae02e49c3.jpg" alt className="img-responsive" style={{width: 500, height: 200}}/>
                                </div>
                                </a>
                                <div className="body">
                                    <div className="title"><a href="https://www.europapress.es/portaltic/ciberseguridad/noticia-firefox-suspende-funcion-compartir-archivos-debido-uso-ciberataques-20200708114748.html" target="_blank" title>Firefox suspende su función de compartir archivos debido a su uso en ciberataques</a></div>
                                    <div className="meta">
                                        <span className="date"><i className="fa fa-clock-o" /> Jul 8, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>
  
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Noticias;