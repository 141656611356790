import React, {Fragment} from 'react';



const Certificaciones = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Certificaciones</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                        <blockquote>
                            <ul>
                            <li>
                            <p>CISSP Certified Information Security Professional (ISC2)</p>
                            </li>
                            <li>
                            <p>CISM Certified Information Security Manager (ISACA)</p>
                            </li>
                            <li>
                            <p>CEH Certified Ethical Hacker (EC-Council)</p>
                            </li>
                            <li>
                            <p>ECSA EC-Council Certified Security Analyst</p>
                            </li>
                            <li>
                            <p>LPT Licensed Penetration Tester (EC-Council)</p>
                            </li>
                            <li>
                            <p>OSCP Offensive Security Certified Professional</p>
                            </li>
                            <li>
                            <p>OSWP Offensive Security Wireless Professional</p>
                            </li>
                            <li>
                            <p>OSCE Offensive Security Certified Expert</p>
                            </li>
                            <li>
                            <p>OSEE Offensive Security Exploit Expe</p>
                            </li>
                            <li>
                            <p>OSWE Offensive Security Web Expert</p>
                            </li>
                            <li>
                            <p>CPTS Certified Penetration Testing Specialist</p>
                            </li>
                            <li>
                            <p>GCIH GIAC Certified Incident Handler</p>
                            </li>
                            <li>
                            <p>GPEN GIAC Certified Penetration Tester</p>
                            </li>
                            <li>
                            <p>FCNSA Fortinet Certified Network Security Associate</p>
                            </li>
                            <li>
                            <p>FCNSP Fortinet Certified Network Security Professional</p>
                            </li>
                            <li>
                            <p>Security + Comptia Security+</p>
                            </li>
                            <li>
                            <p>Network + Comptia Network+</p>
                            </li>
                            <li>
                            <p>ISO27001 ISO27001/2 Lead Auditor</p>
                            </li>
                            <li>
                            <p>Fortinet NSE4 &ndash; NSE7</p>
                            </li>
                            </ul>
                        </blockquote>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            {/* BOX 1 */}
                            <div className="box-news-1">
                                <div className="media gbr">
                                <img src="images/certificaciones/oscp-certs.png"  className="img-responsive" style={{width: 600, height: 200}}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            {/* BOX 2 */}
                            <div className="box-news-1">
                                <div className="media gbr">
                                    <img src="images/certificaciones/oswe.png"  className="img-responsive" style={{width: 600, height: 200}}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <div className="media gbr">
                                    <img src="images/certificaciones/oswp.png"  className="img-responsive" style={{width: 600, height: 200}}/>
                                </div>
                            </div>
                        </div>


                        <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <div className="media gbr">
                                    <img src="images/certificaciones/PTE.jpg"  classname="img-responsive" style={{width: 600, height: 200}} />
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <div className="media gbr">
                                    <img src="images/certificaciones/cissp.jpg"  className="img-responsive" style={{width: 600, height: 200}}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <div className="box-news-1">
                                <div className="media gbr">
                                    <img src="images/certificaciones/ecih.jpg"  className="img-responsive" style={{width: 600, height: 200}}/>
                                </div>
                            </div>
                        </div>




                    </div>




                
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Certificaciones;