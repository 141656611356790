import React, {Fragment} from 'react';
import Banner01 from '../banner/Banner01';



const Waf = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">WAF & DDOS Protection</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>No require hardware adicional.</li>
                            <li>Mitiga ataques de DDoS volum&eacute;tricos.</li>
                            <li>Mitiga ataques de DDoS capa 3,4 y 7.</li>
                            <li>Protecci&oacute;n contra Botnets.</li>
                            <li>M&uacute;ltiples centros de datos a nivel mundial.</li>
                            <li>Soporte especializado y dedicado 24/7.</li>
                            <li>Integraci&oacute;n con redes de Content Delivery, Caching, Compresi&oacute;n y Optimizaci&oacute;n de tr&aacute;fico web.</li>
                            <li>R&aacute;pida y f&aacute;cil implementaci&oacute;n mediante cambios a la configuraci&oacute;n DNS del cliente.</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Waf;