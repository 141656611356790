import React, {Fragment} from 'react';



const Barrido = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Barrido de señales y frecuencias RF</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div>
                        <br /><br />
                    </div>
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                             <p style={{textAlign: 'justify'}}>Servicio para identificaci&oacute;n y detecci&oacute;n de c&aacute;maras, microfonos y equipos de espionaje ocultos.</p>
                            </blockquote>
                        </div>
                    </div>
<div>
  <br /><br /><br />
  <br /><br /><br />
  <br /><br /><br />
  <br /><br /><br />
  <br /><br /><br />
  <br /><br /><br /><br /><br /><br /><br />
</div>

                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Barrido;