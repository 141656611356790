import React, {Fragment,useEffect,useState } from 'react';
import Mapas from '../mapa/Mapas';




const Contacto = () => {
    const urlvista = <div>
  'https://www.google.com/maps/place/8%C2%B058'50.0%22N+79%C2%B031'17.7%22W/@8.9805556,-79.523772,17z/data=!4m5!3m4!1s0x0:0x0!8m2!3d8.9805574!4d-79.5215759?hl=es'
</div>

   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 mt-20">
                            <div className="title-page">Contáctenos</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-md-4 col-md-push-8">
                            <div className="widget contact-info-sidebar">
                                <div className="widget-title">
                                     Información de contacto
                                </div>
                                <ul className="list-info">
                                    <li>
                                        <div className="info-icon">
                                            <span className="fa fa-map-marker" />
                                        </div>
                                        <div className="info-text">Torre BCT Bank, calle 50, piso 17, oficina 1702</div> </li>
                                    <li>
                                        <div className="info-icon">
                                            <span className="fa fa-phone" />
                                        </div>
                                        <div className="info-text">+507 388-4388</div>
                                    </li>
                                    <li>
                                        <div className="info-icon">
                                            <span className="fa fa-envelope" />
                                        </div>
                                        <div className="info-text">info@qsalatam.com</div>
                                    </li>
                                </ul>
                            </div> 
                        </div>
                        
                        <div className="col-sm-8 col-md-8 col-md-pull-4">
                        <div className="content">
                            <form action="#" className="form-contact" id="contactForm" data-toggle="validator" noValidate="true">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="p_name" placeholder="Nombre" required />
                                    <div className="help-block with-errors" />
                                </div>

                                <div className="form-group">
                                    <input type="email" className="form-control" id="p_email" placeholder="Email" required />
                                    <div className="help-block with-errors" />
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" id="p_name" placeholder="Teléfono" required />
                                    <div className="help-block with-errors" />
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" id="p_name" placeholder="Compañía" required />
                                    <div className="help-block with-errors" />
                                </div>

                                <div className="form-group">
                                    <textarea id="p_message" className="form-control" rows={6} placeholder="Mensaje" defaultValue={""} />
                                    <div className="help-block with-errors" />
                                </div>
                                <div className="form-group">
                                    <div id="success" />
                                    <button type="submit" className="btn btn-secondary disabled" style={{pointerEvents: 'all', cursor: 'pointer'}}>Enviar</button>
                            </div>
                            </form>
                            <div className="margin-bottom-25" /><p><em></em></p></div>
                        </div>
                    </div>
         
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.9086246068687!2d-79.52377198521374!3d8.980555593554445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwNTgnNTAuMCJOIDc5wrAzMScxNy43Ilc!5e0!3m2!1ses!2spa!4v1598583554845!5m2!1ses!2spa" width="100%" height={450} frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />


                </div>
            </div>

		</Fragment>
    )
}

export default Contacto;