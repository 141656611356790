import React, {Fragment} from 'react';



const Codigofuente = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Revisión de código fuente</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <blockquote>    
                        <p>En nuestro servicio&nbsp;<strong>Auditor&iacute;a de C&oacute;digo Fuente</strong>&nbsp;evaluamos el grado de seguridad del cde las aplicaciones utilizadas por las empresas (tanto de desarrollo propio como aquellas de terceros que les hayan facilitado el c&oacute;digo) y localizamos las vulnerabilidades que podr&iacute;an ser explotadas por los atacantes.</p>
                        <p>Este servicio permite a los clientes evaluar en profundidad la seguridad de sus aplicaciones m&aacute;s cr&iacute;ticas, especialmente aquellas que manejan informaci&oacute;n corporativa secreta, datos de car&aacute;cter personal u operan transacciones econ&oacute;micas (comercio/banca electr&oacute;nica). El servicio tambi&eacute;n incluye a aquellos productos donde se emplean y adaptan una combinaci&oacute;n y configuraciones concretas de sistemas operativos y aplicaciones con un objetivo com&uacute;n.</p>  
                        </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Codigofuente;