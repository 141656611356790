import React, {Fragment} from 'react';



const Hardening = () => {


   return ( 
		<Fragment>
            {/* BANNER */}
            <div className="section banner-page about">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 mt-20">
                    <div className="title-page">Hardening de Sistemas y Aplicaciones</div>
                </div>
                </div>
            </div>
            </div>
            {/* ABOUT FEATURE */}
            <div className="section feature overlap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                            <blockquote>
                            <ul>
                            <li>Servicio de consultor&iacute;a que se basa en la implementaci&oacute;n de las mejores pr&aacute;cticas y est&aacute;ndares de seguridad de la industria de los sistemas operativos, bases de datos y aplicaciones.</li>
                            <li>Nos especializamos en el endurecimiento de los siguientes tipos de sistemas:</li>
                            <li>Basados en Linux (Red Hat, CentOS, Debian, Ubuntu, Suse).</li>
                            <li>Basados en Unix (AIX, HPUX).</li>
                            <li>Basados en Windows (Windows Server 2003, 2008, 2012).</li>
                            <li>Sun Solaris.</li>
                            </ul>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>



		</Fragment>
    )

}
//export default withRouter(Header);
export default Hardening;